<template>
  <div id="yield" v-if="gaOptions && dataTS" class="scroll">
    <div id="yield__info-container">
      <div class="yield__infobox-yield box-shadow-1">
        <h5>
          Total Yield BreakDown from {{ startDate | formatDate }} to
          {{ endDate | formatDate }}
        </h5>
        <div class="row">
          <div class="col-md-4">
            <ApexChart
              width="200px"
              :options="donutchartOptions"
              :series="donutseries"
            ></ApexChart>
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-12 ">
                <label>Total Nett Mass:</label>
              </div>
              <div class="col-md-12 ">
                <h5>{{ totalNettMass | formatValue }} {{ shortUnitMass }}</h5>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-12 ">
                <label>Total Qty.:</label>
              </div>
              <div class="col-md-12 ">
                <h5>{{ totalNettQty }} units</h5>
              </div>
            </div> -->
            <div class="row">
              <div class="col-md-12 ">
                <label>Avg. Nett Mass:</label>
              </div>
              <div class="col-md-12 ">
                <h5>{{ avgMass | formatValue }} {{ shortUnitMass }}</h5>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-12 ">
                <label>Waste Mass:</label>
              </div>
              <div class="col-md-12 ">
                <h5> {{ wasteMass | formatValue }} {{ shortUnitMass }}</h5>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-12 ">
                <label>Waste Qty:</label>
              </div>
              <div class="col-md-12 ">
                <h5> {{ wasteQty }} units</h5>
              </div>
            </div> -->
            <!-- <div class="row">
              <div class="col-md-12 ">
                <label>Avg. Waste Mass:</label>
              </div>
              <div class="col-md-12 ">
                <h5> {{ avgWasteMass | formatValue }} {{ shortUnitMass }}</h5>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="yield__infobox-yield box-shadow-1">
        <h5>
          Nett Mass by Variant in {{ unitMass }} from
          {{ startDate | formatDate }} to {{ endDate | formatDate }}
        </h5>
        <ApexChart
          :height="`${this.harvestAgg.length * 40 + 60}px`"
          :options="barChart_options"
          :series="barChart_series"
        ></ApexChart>
      </div>

      <div class="yield__infobox-yield box-shadow-1">
        <h5>
          Nett Mass breakdown over time in {{ unitMass }} from
          {{ startDate | formatDate }} to {{ endDate | formatDate }}
        </h5>
        <ApexChart
          type="line"
          height="420px"
          :options="linechartOptions"
          :series="lineChart_series"
        ></ApexChart>
      </div>

      <div class="yield__infobox-yield box-shadow-1">
        <b-button class="yield__btn-download" variant="primary" v-on:click="downloadCSV">
          Download CSV
        </b-button>
        <vue-good-table
          :columns="cols"
          :rows="harvestAgg"
          styleClass="vgt-table yield__harvest shadow-box-1"
          max-height="50vh"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'z'">
              {{ props.row.z }} units
            </span>
            <span v-else-if="props.column.field == 'y'">
              {{ props.row.y }} {{ shortUnitMass }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>

    <div id="yield__filters" class="box-shadow-1">
      <p class="yield__filters__data-timestamp"> {{ dataTS | formatTime }} </p>
      <hr />
      <h6> Time Shortcuts </h6>
      <b-button-group>
        <b-button
          class="yield__filters__btnFilter btn-sm"
          @click="onGroupButtonFilterChange('1D')"
          >1D</b-button
        >
        <b-button
          class="yield__filters__btnFilter btn-sm"
          @click="onGroupButtonFilterChange('1W')"
          >1W</b-button
        >
        <b-button
          class="yield__filters__btnFilter btn-sm"
          @click="onGroupButtonFilterChange('1M')"
          >1M</b-button
        >
        <b-button
          class="yield__filters__btnFilter btn-sm"
          @click="onGroupButtonFilterChange('3M')"
          >3M</b-button
        >
        <b-button
          class="yield__filters__btnFilter btn-sm"
          @click="onGroupButtonFilterChange('6M')"
          >6M</b-button
        >
      </b-button-group>
      <hr />
      <h6> Start Date </h6>
      <b-form-input
        id="sdate"
        type="date"
        v-model="startDate"
        v-on:input="changeDate"
        :max="edate"
        required
      >
      </b-form-input>
      <h6> End Date </h6>
      <b-form-input
        id="edate"
        type="date"
        v-model="endDate"
        v-on:input="changeDate"
        :max="maxDate"
        :min="sdate"
        required
      >
      </b-form-input>
      <hr />
      <h6> Growarea </h6>
      <v-select
        id="selectGA"
        :clearable="false"
        :searchable="false"
        :options="gaOptions"
        v-model="selectedGA"
        @input="getHarvests(true)"
      ></v-select>
      <hr />
      <h6> Units </h6>
        KG
        <app-switch
          id="units"
          classes="is-warning"
          @input="onUnitSwitch"
          :checked="switchValue"
        >
          Grams
        </app-switch>
      <hr />
      <h6> Variant </h6>
      <MultiSelect
        id="txtVariantSelection"
        v-model="selectedTags"
        tag-placeholder="Add this as new tag"
        placeholder="Search or add a tag"
        :options="tagOptions"
        :multiple="true"
        :taggable="true"
        :close-on-select="false"
        :clear-on-select="false"
        @input="getHarvests(false);"
      >
      </MultiSelect>
      <hr />
    </div>
  </div>
  <YieldSkeleton v-else/>
</template>

<script>
import SwitchControl from "../components/SwitchControl.vue";
import YieldSkeleton from '../components/yield/YieldSkeleton.vue';
import axios from "axios";
import moment from "moment";
import router from "../router";

export default {
  name: "Harvests",
  components: {
    "app-switch": SwitchControl,
    YieldSkeleton
  },
  filters: {
    formatValue: function (value) {
      return Math.round((value + Number.EPSILON) * 100) / 100;
    },
    formatDate: function (value) {
      if (value) {
        return moment(value, "YYYY-MM-DD").format("DD-MM-YYYY");
      }
    },
    formatTime: function (value) {
      if (value) {
        return moment(value, "YYYY-MM-DD").format("DD-MM-YYYY HH:mm");
      }
    },
  },
  props: {
    farm: String,
    sdate: {
      type: String,
      default: moment().subtract(7, "d").format("YYYY-MM-DD"),
    },
    edate: {
      type: String,
      default: moment().format("YYYY-MM-DD"),
    },
  },
  data() {
    return {
      switchValue: false,
      unitMass: "kilograms",
      shortUnitMass: "kg",
      selectedGA: "all",
      gaOptions: ["all"],
      dataTS: null,
      selectedTags: [],
      tagOptions: [],
      btnGroupFilterId: "1W",
      donutseries: [],
      donutchartOptions: {
        chart: {
          type: "donut",
          height: 300,
          width: 300
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          // formatter: function (seriesLabel, opts) {
          //   const val = Math.round((opts.w.globals.series[opts.seriesIndex] + Number.EPSILON) * 100) / 100;
          //   return seriesLabel + " - " + val;
          // },
          // floating: true,
          position: "bottom",
        },
        labels: ["Nett Yield", "Waste"],
        colors: [ "#009933", "#ff0000" ],
        responsive: [
          {
            breakpoint: 300,
            options: {
              chart: {
                width: 150,
                height: 150
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      lineChart_series: [],
      linechartOptions: {
        chart: {
          height: 250,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        yaxis: { decimalsInFloat: 2 },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5
        },
        colors: [ "#009933", "#ff0000" ],
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "#ffffff"],
            opacity: 0.5,
          },
        },
      },
      barChart_series: [],
      barChart_options: {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          }
        },
        xaxis: {
          type: "category",
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#000000"],
          },
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            const value = Math.round((val + Number.EPSILON) * 100) / 100;
            return w.globals.labels[dataPointIndex] + ":  " + value + " " + w.config.series[seriesIndex].name;
          },
          offsetX: 0,
          // dropShadow: {
          //   enabled: true,
          //   color: '#ffffff'
          // },
        },
        title: {
          align: "center",
          floating: true,
        },
        tooltip: {
          theme: "dark",
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
            formatter: function (val, { seriesIndex, dataPointIndex, w }) {
              const value = Math.round((val + Number.EPSILON) * 100) / 100;
              return w.globals.labels[dataPointIndex] + ":  " + value + " " + w.config.series[seriesIndex].name;
            },
          },
          z: {
            title: "",
            formatter: function (val) {
              return val + " units";
            },
          },
        },
      },
      cols: [
        {
          label: "Variant",
          field: "x",
        },
        {
          label: "Qty",
          field: "z",
        },
        {
          label: "Nett Mass",
          field: (rowObj) => {
            return `${Math.round((rowObj.y + Number.EPSILON) * 100) / 100} ${
              this.shortUnitMass
            }`;
          },
        },
        {
          label: "Avg. Nett Mass",
          field: (rowObj) => {
            return `${(rowObj.y / rowObj.z).toFixed(2)} ${
              this.shortUnitMass
            }/unit`;
          },
        },
        {
          label: "Waste Mass",
          field: (rowObj) => {
            return `${Math.round((rowObj.waste + Number.EPSILON) * 100) / 100} ${
              this.shortUnitMass
            }`;
          },
        },
      ],
      yield: [],
      harvestAgg: [],
      totalNettMass: -1,
      totalNettQty: -1,
      avgMass: -1,
      wasteMass: -1,
      wasteQty: -1,
      avgWasteMass: -1,
      startDate: this.sdate,
      endDate: this.edate,
      maxDate: moment().format("YYYY-MM-DD")
    };
  },
  watch: {
    farm: function () {
      this.gaOptions = ["all"];
      this.getGrowAreas();
      this.getHarvests(true);
    },
  },
  mounted() {
    this.getGrowAreas();
    this.getHarvests(true);
  },
  methods: {
    onUnitSwitch(val) {
      this.switchValue = val;
      if (val) {
        this.unitMass = "grams";
        this.shortUnitMass = "g";
      } else {
        this.unitMass = "kilograms";
        this.shortUnitMass = "kg";
      }

      this.getHarvests(false);
    },
    onGroupButtonFilterChange(newValue) {
      this.btnGroupFilterId = newValue;
      this.endDate = moment().format("YYYY-MM-DD");
      if (newValue == "1D") {
        this.startDate = moment().format("YYYY-MM-DD");        
      } else if (newValue == "1W") {
        this.startDate = moment().subtract(7, "d").format("YYYY-MM-DD");
      } else if (newValue == "1M") {
        this.startDate = moment().subtract(30, "d").format("YYYY-MM-DD");
      } else if (newValue == "3M") {
        this.startDate = moment().subtract(90, "d").format("YYYY-MM-DD");
      } else if (newValue == "6M") {
        this.startDate = moment().subtract(182, "d").format("YYYY-MM-DD");
      }

      router.replace({
        name: "yield",
        params: { farm: this.farm },
        query: { sdate: this.startDate, edate: this.endDate },
      });
      this.getHarvests(true);
    },
    changeDate() {
      router.replace({
        name: "yield",
        params: { farm: this.farm },
        query: { sdate: this.startDate, edate: this.endDate },
      });
      this.getHarvests(true);
    },
    getHarvests(isPageLoad) {
      axios
        .get(`/${this.farm}/yield`, {
          params: {
            dated: moment(this.startDate).toISOString(),
            dateu: moment(this.endDate).add(1, 'd').subtract(1, 's').toISOString(),
            growarea: this.selectedGA != "all" ? this.selectedGA : null
          },
        })
        .then((response) => {          
          this.yield = response.data.map((d) => {
            d['variant'] = d['variant'].toUpperCase();
            d['date'] = new Date(d.date).toLocaleString();
            return d;
          });
          this.dataTS = new Date();
          console.log(this.dataTS)
          this.bindHarvertData(isPageLoad);
        })
        .catch((e) => {
          console.log(e);
          if (e.response?.status == 500) this.$router.push("/error")
        });
    },
    bindHarvertData(isPageLoad) {
      if (!this.switchValue) {
        this.yield.map(function (value) {
          // console.log('key ' + key)
          value.mass = parseFloat(value.mass) / 1000;
        });
      }

      // Filter variants based on selectedTags
      if (!isPageLoad){
        console.log(this.selectedTags);
        const selVars = this.selectedTags;
        this.yield = this.yield.filter((d) => {
          return selVars.includes(d.variant);
        });
      }

      this.totalNettMass = 0;
      this.totalNettQty = 0;
      this.wasteMass = 0;
      this.wasteQty = 0;

      // Groups the yield data by variant
      var groupedData = this.yield.reduce((res, val) => {
        res[val.variant] = res[val.variant] || {
          x: val.variant,
          z: 0,
          y: 0,
          waste: 0
        };

        if(val.label === 0){
          this.wasteQty += val.quantity;
          this.wasteMass += val.mass;
          res[val.variant].waste += parseFloat(val.mass);
        }
        else{
          this.totalNettMass += val.mass;
          this.totalNettQty += val.quantity;
          res[val.variant].y += parseFloat(val.mass);
          res[val.variant].z += val.quantity;
        }

        return res;
      }, {});

      this.avgMass = this.totalNettMass / this.totalNettQty;
      this.avgWasteMass = this.wasteQty <= 0 ? 0 :this.wasteMass / this.wasteQty;

      if (isPageLoad) {
        this.selectedTags = [];
        this.tagOptions = [];

        // Add the available variants and select them
        for (const variant in groupedData) {
          var newtag = variant;
          this.tagOptions.push(newtag);
          this.selectedTags.push(newtag);
        }
      }

      // Re-map the object
      groupedData = Object.keys(groupedData).map((key) => {
        return groupedData[key];
      });

      this.barChart_series = [{ name: this.shortUnitMass, data: groupedData }];
      this.harvestAgg = groupedData;

      ("line data");
      // Build a timeseries of the yield data with aggregated sums of clean yield, waste
      let timeSeriesYield = this.yield.reduce((res, val) => {
        res[moment(val.date, "L", moment.locale()).format("DD/MM/YYYY")] = res[
          moment(val.date, "L", moment.locale()).format("DD/MM/YYYY")
        ] || {
          x: moment(val.date, "L", moment.locale()).format("DD/MM/YYYY"),
          waste: 0,
          cleanQty: 0,
          cleanYield: 0
        };

        if(val.label === 0){
          res[moment(val.date, "L", moment.locale()).format("DD/MM/YYYY")].waste += parseFloat(val.mass);
        }
        else{
          res[moment(val.date, "L", moment.locale()).format("DD/MM/YYYY")].cleanYield += parseFloat(val.mass);
          res[moment(val.date, "L", moment.locale()).format("DD/MM/YYYY")].cleanQty += val.quantity;
        }

        return res;
      }, {});

      let timeNettMass = Object.keys(timeSeriesYield).map((key) => {
        let remapNett = {x: timeSeriesYield[key].x, y: timeSeriesYield[key].cleanYield};
        return remapNett;
      });
      var sortedNettMass = timeNettMass.sort(
        (a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()
      );

      let timeWasteMass = Object.keys(timeSeriesYield).map((key) => {
        let remapWaste = {x: timeSeriesYield[key].x, y: timeSeriesYield[key].waste};
        return remapWaste;
      });
      var sortedWasteMass = timeWasteMass.sort(
        (a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()
      );

      this.lineChart_series = [
        {
          name: "Nett Mass",
          data: sortedNettMass,
        },
        {
          name: "Waste Mass",
          data: sortedWasteMass,
        }
      ];

      ("Donut Chart");
      this.donutseries.length = 0;
      this.donutseries.push(this.totalNettMass);
      this.donutseries.push(this.wasteMass);
    },
    getGrowAreas(){
      axios
        .get(`/${this.farm}/zones`, { })
        .then((response) => {
          const growareas = response.data;
          for (const ga in growareas){
            this.gaOptions.push(growareas[ga]);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status == 500) this.$router.push("/error")

        });
    },
    downloadCSV() {
      var csv =
        "date, hsId, variant, imgUrl, oriUrl, growarea, label, quantity, mass\n";
      this.yield.forEach((row) => {
        csv +=
          `"${row.date}",` +
          `${row.hsId},` +
          `${row.variant},` +
          `${row.imgUrl || ""},` +
          `${row.oriUrl || ""},` +
          `${row.growarea},` +
          `${row.label},` +
          `${row.quantity},` +
          `${row.mass}\n`;
      });

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = "harvestData.csv";
      anchor.click();
    },
  },
};
</script>

<style scoped>
.yield__harvest {
  font-size: 13px;
}

#yield__filters {
  position: absolute;
  top: 75px;
  right: 0;
  width: 240px;
  height: 160vh;
  background: rgba(240, 255, 240, 0.5);
  padding: 5px 10px;
  margin-top: 30px;
  margin-right: 5px;
}

#yield{
  height: 100%;
}

#yield__info-container {
  margin-right: 240px;
  display: flex;
  flex-flow: row wrap;
  text-align: left;
}

.yield__filters__btnFilter {
  background-color: #abaeb0;
  color: #f9fdff;
}

.yield__btn-download {
  margin: 5px 5px;
}

.yield__infobox-yield {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  margin: 10px 10px 30px 0px;
  padding: 20px 20px;
  width: 100%;
}

.spacemid {
  margin-top: 25px;
}

.space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.multiselect {
  margin-top: 5px;
  margin-bottom: 5px;
  max-height: 200px;
}

.form-group {
  margin-bottom: 0;
}

.yield__filters__data-timestamp {
  bottom: 0;
  text-align: center;
}
</style>
